<template>
 <div class="course-item">
      <div class="module-title">
        <img
          :src="imgUrl"
        />
        <p>{{name}}</p>
        <!-- <span @click="toList('LessonTuoyu', id)"
          >更多
          <van-icon name="arrow" color="#144094" size="16" />
        </span> -->
      </div>
      <div class="course-list">
        <div
          class="course-card"
          :style="{width: width}"
          v-for="card of list"
          :key="card.id"
          @click="jumpLesson(card)"
        >
          <div
            class="course-card-cover"
           
          >
            <van-image
              class="course-cover"
              width="100%"
              fit="cover"
              :error-icon="$placeImg"
              :loading-icon="$placeImg"
              :src="name=='托育职业教育'? card.master_cover : card.cover_mobile"
            >
              <template v-slot:error>
                <van-image
                  style="width: 100%; height: 100%"
                  fit="cover"
                  :src="$placeImg"
                ></van-image>
              </template>
            </van-image>
          </div> 
        </div>
      </div>
    </div>
</template>

<script>
import myAudio from '@/components/Audio'
export default {
  name: "Home",
  components:{myAudio},
  props:['list','name','imgUrl','id','width','height'],
  data() {
    return {
      finished: false,
      isShowAudioPlayer:false,
      currentInfo:null,
      currentIndex:0,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  mounted() {

  },

  methods: {
     // 去列表
     toList(url, id, index) {
    
      let myUrl = `/${url}?id=${id}`;
      console.log(url);
      console.log(id);
      if (index) myUrl = myUrl + "&index=" + index;
      this.$until.toPageOpen(myUrl);
    },
    jumpLesson(item) {
      if(item.id=='745' || item.id=='746'){
        this.$toast('敬请期待');
        return
      }
      if(this.$until.loginRequire()){
        this.$until.toPageOpen(`/detail2?id=${item.id}`)
      }
      
    },
  },
  

};
</script>

<style lang="scss" scoped>
.module-title {
    font-size: 36px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 89px;
    padding-bottom: 41px;
    img {
      margin-right: 10px;
    }
    p {
      flex: 1;
      font-weight: bold;
      font-family: "SourceHanSans";
    }
    span {
      color: #006bb3;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .course-card {
    margin-right: 24px;
    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }
    .course-card-cover {
      height: auto;
      padding-bottom: 0;
      .course-cover {
        padding-bottom: 0;
        display: block;
        flex-shrink: 0;
      }
    }
    
  }
  .course-item {
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .levelTitle {
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      p {
        cursor: pointer;
        font-size: 16px;
        color: #0080d6;
      }
    }
  }
  .course-list {
    padding-top: 10px;
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > div {
      border-radius: 8px;
      margin-right: 20px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }

    .levelCate {
      cursor: pointer;
      width: calc((100% - 60px) * 0.25);

      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      b {
        width: 143px;
        height: 35px;
        font-size: 36px;
        font-family: HarmonyOS Sans SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 62px;
        text-shadow: 0px 3px 0px #184a8c;
        padding-top: 39px;
        padding-left: 19px;
      }

      p {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        color: #dbdbdb;
        padding-left: 22px;
        align-items: center;
      }
    }
    .card-btn {
      cursor: pointer;
      border-radius: 6px;
      line-height: 28px;
      border-radius: 6px;
      color: #0080d6;
      background: #f7f7f7;
      padding: 0 20px;
      font-size: 16px;
      flex: 0 0 auto;
      text-align: center;
      margin-top: 4px;

      &:hover {
        color: #fff;
        background: #0080d6;
      }
    }
  }
</style>

