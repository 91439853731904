<template>
 <div class="course-card2" style="padding-bottom: 40px">
      <div class="module-title">
        <img
          :src="imgUrl || 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_gp.jpg'"
        />
        <p>{{name}}</p>
        <span @click="toList('LessonTuoyu', id)" v-if="id"
          >更多
          <van-icon name="arrow" color="#006bb3" size="16" />
        </span>
      </div>
      <div
        class="course-card2-item"
        v-for="card of list"
        :key="card.id"
        @click="jumpLesson(card)"
      >
        <div class="course-card-cover" @mousemove="imgScaleIndex=card.id"
            @mouseout="imgScaleIndex=''">
          <div class="tip">
            <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
            <img :src="$free" v-if="card.is_free == 2" />
            <img :src="$vip" v-else-if="card.is_vip_flat == 1" />
            <img :src="$noVip" v-else />
          </div>
          <van-image
            class="course-cover"
            :class="{imgBig:imgScaleIndex == card.id,imgSmall:imgScaleIndex != card.id}"
            
            width="100%"
            :error-icon="$placeImg"
            :loading-icon="$placeImg"
            :src="card.cover_mobile"
          >
            <template v-slot:error>
              <van-image
                style="width: 100%; height: 100%"
                fit="cover"
                src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"
              ></van-image>
            </template>
          </van-image>
        </div>
        <div class="course-card-detail">
          <p class="course-card-title">{{ card.name }}</p>
          <p class="detail">{{ card.description }}</p>

          <p class="lessonTime">{{ card.master_name}}</p>
          <div>
            <p class="price">
              <!-- ￥<b>{{ card.price }}</b> -->
              <!-- 限时免费 -->
            </p>
            <p>{{ card.ordernum }}人已学习</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import myAudio from '@/components/Audio'
export default {
  name: "Home",
  components:{myAudio},
  props:['list','name','imgUrl','id'],
  data() {
    return {
      imgScaleIndex:-1,
      width: 1200,
      finished: false,
      isShowAudioPlayer:false,
      currentInfo:null,
      currentIndex:0,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  mounted() {

  },

  methods: {
     // 去列表
     toList(url, id, index) {
    
      let myUrl = `/${url}?id=${id}`;
      console.log(url);
      console.log(id);
      if (index) myUrl = myUrl + "&index=" + index;
      this.$until.toPageOpen(myUrl);
    },
    jumpLesson(item) {
   
      if(this.$until.loginRequire()){
        this.$until.toPageOpen(`/detail2?id=${item.id}`)
      }
      
    },
  },
  

};
</script>

<style lang="scss" scoped>
.module-title {
    font-size: 36px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 89px;
    padding-bottom: 41px;
    img {
      margin-right: 10px;
    }
    p {
      flex: 1;
      font-weight: bold;
      font-family: "SourceHanSans";
    }
    span {
      color: #006bb3;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .course-card2 {
    padding-top: 37px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 100vw;
      min-width: 1200px;
      height: 100%;
      background: #fff;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
    }
    .module-title {
      padding-top: 42px;
      padding-bottom: 11px;
    }
    @keyframes toScale {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.2);
      }
    }
    @keyframes toScaleSmall {
      from {
        transform: scale(1.2);
      }
      to {
        transform: scale(1);
      }
    }
    .course-cover {
      flex-shrink: 0;
      ::v-deep img {
        cursor: pointer;
      }
    }
    .imgBig{
      ::v-deep img {
        animation: toScale 0.3s;
        transform: scale(1.2);
      }
      
    }
    .imgSmall{
      ::v-deep img {
        animation: toScaleSmall 0.3s;
        transform: scale(1);
      }
      
    }
    .course-card2-item {
      padding: 29px 0;
      width: 100%;
      cursor: pointer;
      display: flex;
      &:not(:last-of-type) {
        background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/line.jpg")
          bottom no-repeat;
      }
    }
    .course-card-cover {
      width: 560px;
      height: 315px;
      border-radius: 10px;
      overflow: hidden;
      flex-shrink: 0;
    }
    .course-card-detail {
      flex: 1;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      .course-card-title {
        font-size: 20px;
        font-weight: bold;
      }
      .detail {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #666666;
        line-height: 26px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;

      }
      .lessonTime {
        flex: 1;
        font-size: 16px;
        color: #006bb3;
      }
      > div {
        display: flex;
        justify-content: space-between;
        color: #7f7f7f;
        .price {
          color: #ff6c00;
          font-size: 16px;
          b {
            font-size: 24px;
          }
        }
      }
    }
  }
</style>

